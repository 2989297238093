cs.ns('app.ui.composite.content');
app.ui.composite.content.view = cs.clazz({
    extend:   app.fw.abstract.view,
    dynamics: {
        markupName:     'appContent',
        currentContent: null,
        nextContent:    null,
        fadeInPromise:  null,
        fadeOutPromise: null
    },
    protos:   {

        prepareMaskReferences () {
            this.base()

            this.currentContent = $('.appCurrentContent', this.ui)
            this.nextContent    = $('.appNextContent', this.ui)
        },

        registerDataBindings () {
            this.base()

            this.observeOwnModel('data:contentURL', (ev, url) => {
                const content = cs(this).value('global:data:currentContent')
                this.nextContent.empty()
                if (url) {
                    let markup
                    if (content.type === 'image' || content.type === 'video' || content.type === 'app') {
                        markup = this.nextContent.markup(`${this.markupName}.${content.type}`, { content: content, url: url })
                    }
                    if (content.type === 'video') {
                        let $video = markup.filter('video')
                        $video
                            .on('ended', (/*ev*/) => {
                                cs(this).value('event:videoEnded', true)
                            })
                            .on('timeupdate', (ev) => {
                                let t = ev.target;
                                if (t.buffered && t.buffered.length) {
                                    let bufTimeInPercent = typeof t.duration === 'number' ? t.buffered.end(0) / t.duration * 100 : 0
                                    $('.appVideoBuffer', markup).width(bufTimeInPercent + '%')
                                }
                                let playTimeInPercent = typeof t.duration === 'number' ? t.currentTime / t.duration * 100 : 0
                                $('.appCurrentPlaytime', markup).width(playTimeInPercent + '%')
                                $('.appCurrentPosition', markup).css('left', playTimeInPercent + '%')
                            })
                            .on('loadeddata', () => {
                                if (!cs(this).value('global:state:paused')) {
                                    let res = $video[0].play()
                                    if (res && res.then) {
                                        res.then(null, () => {
                                            // play() failed - so we reset the global state paused back to true
                                            cs(this).value('global:state:paused', true)
                                        })
                                    }
                                }
                            })
                            .on('error', () => {
                                cs(this).value('event:error', true)
                            })
                        $('source', $video).on('error', () => {
                            cs(this).value('event:error', true)
                        })
                        $(markup).filter('.appPlayBar')
                            .on('mouseover mousemove', (ev) => {
                                let percent     = ev.pageX / $(window).width() * 100
                                let overallTime = $video[0].duration
                                let focusedTime = Math.round(overallTime / 100 * percent)
                                let focusedHours = Math.floor(focusedTime / 3600)
                                let focusedMinutes = Math.floor(focusedTime % 3600 / 60)
                                let focusedSeconds = focusedTime % 60
                                let text = `${("0" + focusedSeconds).substr(-2)}`
                                if (focusedHours > 0) {
                                    text = `${focusedHours}:${("0" + focusedMinutes).substr(-2)}:${text}`
                                } else {
                                    text = `${focusedMinutes}:${text}`
                                }
                                $('.appTimeTooltip', markup)
                                    .text(text)
                                    .css('left', percent + '%')
                                    .show()
                            })
                            .on('mouseout', (/*ev*/) => {
                                $('.appTimeTooltip', markup).hide()
                            })
                            .on('click', (ev) => {
                                let percent     = ev.pageX / $(window).width() * 100
                                let overallTime = $video[0].duration
                                let currentTime = overallTime / 100 * percent
                                $video[0].currentTime = currentTime
                            })
                    } else if (content.type === 'image') {
                        $("img", markup).on('error', () => {
                            cs(this).value("event:error", true)
                        })
                        let $img = markup.filter(".img")
                        $img.css("background-image", `url(${url})`)
                    }
                }
                this.activateContent()
            })

            this.observeParentModel('global:data:qrcode', (ev, qrcode) => {
                $(this.vue.$refs.qrCode).empty()
                if (qrcode) {
                    this.qrcode = new QRCode(this.vue.$refs.qrCode, {
                        text:         qrcode,
                        width:        160,
                        height:       160,
                        colorDark:    "#666",
                        colorLight:   "transparent",
                        correctLevel: QRCode.CorrectLevel.L
                    })
                }
            }, { boot: true })

        },

        registerCommandBindings() {
            this.base()

            this.observeOwnModel('command:pauseVideo', () => {
                $('video', this.ui)[0].pause()
            })

            this.observeOwnModel('command:resumeVideo', () => {
                $('video', this.ui)[0].play()
            })
        },

        activateContent () {
            const inP  = new Promise((resolve, reject) => {
                if (this.nextContent.css("opacity") === '0') {
                    this.nextContent.fadeTo(2000, 1, () => {
                        resolve()
                    })
                } else {
                    reject(new Error("canceled"))
                }
            })
            const outP = new Promise((resolve, reject) => {
                if (this.currentContent.css("opacity") === '1') {
                    this.currentContent.fadeTo(2000, 0, () => {
                        resolve()
                    })
                } else {
                    reject(new Error("canceled"))
                }
            })

            Promise.join(inP, outP).then(() => {
                this.nextContent.removeClass('appNextContent').addClass('appCurrentContent')
                this.currentContent.removeClass('appCurrentContent').addClass('appNextContent')

                this.currentContent = $('.appCurrentContent', this.ui)
                this.nextContent    = $('.appNextContent', this.ui).empty()
                cs(this).value("event:contentChanged", true)
            }, () => {})
        }

    }
});
